
import ChatUx from "../components/ChatUx";


function Chat() {


    return <>
        <ChatUx/>
    </>

}

export default Chat
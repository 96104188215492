import styles from "./stylesheets/chatsidebar.module.css";

function ChatSideBar(prop){


    return(<div className={styles.sidebar}>
        <ul>

            <li className={styles.inactive}> <a href="#"    target="_self">Home</a> </li>
            <li className={styles.inactive}> <a href="#"   target="_self">Chats</a> </li>
            <li className={styles.inactive} > <a href="#"   target="_self">Contacts</a> </li>
            <li className={styles.inactive} > <a href="#"   target="_self">Settings</a> </li>
            <li className={styles.inactive} > <a href="#"   target="_self">Log Off</a> </li>

        </ul>


    </div>)
}

export default ChatSideBar